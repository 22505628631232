
import Api from "@/services/Api";
import { defineComponent, Ref, ref, onMounted, watch } from "vue";
import type { PropType } from "vue";
import useAlert from '@/composables/Alert'
import useEmitter from "@/composables/Emmiter";
import Button from "@/components/Button.vue"



export default defineComponent({
  name: "ModalUsers",
  components: {
    Button,
  },
  props: {
    usuarioProps: Object as PropType<any>,
    fechaModalProps: Function,
    abreModal: Function,
  },

  setup(props) {

    const { showTimeAlert } = useAlert()
    const emitter = useEmitter()

    const usuario: Ref<any> = ref({});
    const arrayLojas: Ref<any[]> = ref([]);
    const arrayGrupos: Ref<any[]> = ref([104,82]);
    const usuarioLojas: Ref<any> = ref([]);
    const usuarioGrupos: Ref<any> = ref([]);

    const loadSave: Ref<boolean> = ref(false);
    const loadExcluir: Ref<boolean> = ref(false);
    const loadRemoveUsuario: Ref<boolean> = ref(false);
    const loadResetarSenha: Ref<boolean> = ref(false);
    const dialogVisible: Ref<boolean> = ref(false);

    const clikouResetarSenha: Ref<boolean> = ref(false);
    const novaSenha: Ref<string> = ref('ilub');


    const inputComMascara: Ref<string> = ref('');
    const adicionarUsuarioEmNovaLoja: Ref<boolean> = ref(false)

    const arrayCargos: Ref<any[]> = ref([]);
    const idLoja: Ref<any> = ref(null);
    const userIsAdmin: Ref<any> = ref(false);



    // ---------------------------------
    function formatarInput(type: string, event) {

      if (type === 'documento') {
        inputComMascara.value = event.replace(/\D/g, "");
        inputComMascara.value = inputComMascara.value.replace(/(\d{3})(\d)/, "$1.$2");
        inputComMascara.value = inputComMascara.value.replace(/(\d{3})(\d)/, "$1.$2");
        inputComMascara.value = inputComMascara.value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

        usuario.value.documento = inputComMascara.value;
      }

      if (type === 'telefone') {
        inputComMascara.value = event.replace(/\D/g, "");
        inputComMascara.value = inputComMascara.value.replace(/^(\d\d)(\d)/g, "($1) $2");
        inputComMascara.value = inputComMascara.value.replace(/(\d{5})(\d)/, "$1-$2");

        usuario.value.telefone = inputComMascara.value;
      }

      return ''

    }
    // ---------------------------------

    function fechaModal(){

      props.fechaModalProps && props.fechaModalProps()

    }

    function abreModal(){
      usuario.value = { ...props.usuarioProps } 
      clikouResetarSenha.value = false
      getLojasUsuario()
      getGruposUsuario()

    }

    function enviaUsuarioAtualizado(usuarioParam) {
      emitter.emit('enviaUsuarioAtualizado', { usuario: usuarioParam })
    }

    function removeUsuarioLista(usuarioParam) {
      emitter.emit('removeUsuarioLista', { usuario: usuarioParam })
    }


    //     FUNÇÕES PRINCIPAIS     //
    // 
    //      ||            ||      //
    //      ||            ||      //
    //      ||            ||      //
    //      ||            ||      //
    //     \  /          \  /     //
    //      \/            \/      //


    async function resetarSenha() { 

      loadResetarSenha.value = true

      const { data } = await Api.post("resetarSenha", { usuario: usuario.value, novaSenha: novaSenha.value })

      loadResetarSenha.value = false

      if(!data.usuario) return showTimeAlert(data?.message, "error")

      novaSenha.value = ""

      return showTimeAlert('Senha resetada com sucesso!', "success")
      
    }

    async function deletarUsuario() { 

      loadExcluir.value = true

      const { data } = await Api.delete("deletaUsuario", { id: usuario.value.id, nivel: usuario.value.nivel })

      loadExcluir.value = false

      if(!data.usuario) return showTimeAlert(data?.message, "error")

      removeUsuarioLista(data.usuario)

      dialogVisible.value = false;
      fechaModal()

      return showTimeAlert('usuário deletado com sucesso!', "success")
      
    }

    async function salvaUsuario() {

      loadSave.value = true

      const { data } = await Api.post("salvaUsuario", { usuario: usuario.value, arrayIdLoja: [ ...usuarioLojas.value, idLoja.value ], idGrupos: usuarioGrupos.value});

      loadSave.value = false

      if(!data.usuario) return showTimeAlert(data?.message, "error");

      enviaUsuarioAtualizado(data.usuario)

      fechaModal()

      return showTimeAlert('usuário salvo com sucesso!', "success")

    }

    async function getLojas() {

      const res = await Api.get("getAllStores");

      arrayLojas.value = res.data.lojas;

    }


    async function getCargo() {

      arrayCargos.value = (await Api.get('getCargosUsuario') as any).data.cargos || []

    }

    

    async function getGruposUsuario() {
      //cargo de GestorRegional
      usuarioGrupos.value = []
      if(usuario.value.idCargo === 2){
        const { data } = await Api.get(`buscaGruposUsuario?idUsuario=${usuario.value.id}`)
        usuarioGrupos.value = data.grupos.map(({codGrupo})=>codGrupo)
      }
    }

    async function getAllGroups(){
      const { data } = await Api.get('getAllGroups')
      arrayGrupos.value = data.grupos
    }

    
    async function getLojasUsuario(){

      const { data } = await Api.get('buscaLojasUsuario', {idUsuario: usuario.value.id})

      usuarioLojas.value = data.lojas.map(({id})=>id)

    }

    async function removeLojaUsuario() {

      loadRemoveUsuario.value = true

      const { data } = await Api.post("removeLojaUsuario", { idUsuario: usuario.value.id, arrayIdLoja: [idLoja.value] })

      loadRemoveUsuario.value = false

      if(!data.usuario) return showTimeAlert(data.message, "error");
      
      showTimeAlert('usuário removido com sucesso!', "success");

      removeUsuarioLista(data.usuario)

      dialogVisible.value = false;
      fechaModal()

    }
    function getLocalStorage(){
      userIsAdmin.value = JSON.parse(localStorage.getItem('user') as any).idCargo === 1
    }
    
    emitter.on("idLojaUser", async params => {
       idLoja.value = params.idLoja;
    });


    // abrindo modal com usuario
    watch(() => props.usuarioProps, async () => { abreModal() }, { deep: true })

    onMounted(async()=>{
      getLocalStorage()
      getCargo()
      getLojas()
      getAllGroups()
    })
   
    return {
      usuario,
      fechaModal,
      deletarUsuario,
      salvaUsuario,
      resetarSenha,
      formatarInput,
      loadSave,
      loadRemoveUsuario,
      arrayCargos,
      loadExcluir,
      arrayLojas,
      adicionarUsuarioEmNovaLoja,
      clikouResetarSenha,
      novaSenha,
      loadResetarSenha,
      usuarioLojas,
      idLoja,
      removeLojaUsuario,
      dialogVisible,
      usuarioGrupos,
      arrayGrupos,
      userIsAdmin
    };
  },
});
