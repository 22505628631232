
import {
  defineComponent,
  Ref,
  ref,
  onMounted,
watch,
} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import ModalUsers from "./components/ModalUsers.vue";
import Title from "@/components/Title.vue"
import Button from "@/components/Button.vue"
import { useRouter,useRoute, LocationQueryValue } from "vue-router";
import { Modal } from "bootstrap";
import Api from "@/services/Api";
import useEmitter from "@/composables/Emmiter";

interface Input {
  cargo: string,
  data: string
}


export default defineComponent({
  name: "Users",

  components: {
    ModalUsers,
    Title,
    Button
  },

  setup() {
    const router = useRouter();

    const route = useRoute();
    const emitter = useEmitter();

    const pagina: Ref<number> = ref(1);
    const limite: Ref<number> = ref(20);

    const usersArray: Ref<any[]> = ref([]);

    const usuarioModal: Ref<any> = ref({})
    const modalUsuario: Ref<any> = ref()

    //refs recebidos do FilterUser
    const input: Ref<Input> = ref({
      cargo: "",
      data: ""
    });

    const idLoja: Ref<LocationQueryValue[] | string | null> = ref(null)
    const lojaFixada: Ref<any> = ref(null)
    const fastFind: Ref<string> = ref('');


    function openModal(usuario?) {
      pegarLojaId()
      usuarioModal.value = usuario || {}
      modalUsuario.value.show()

    }

    async function fechaModal() {
      
      modalUsuario.value.hide()

    }


    async function buscaUsuario(paginaParam: number,  search = false){

      const { data } = await Api.get("buscaUsuarioPaginado", { pagina: paginaParam, limite: limite.value, idLoja: idLoja.value, cargo: input.value.cargo, data: input.value.data , input: fastFind.value} )
      
      //quando eu chamo a busca de usuario pelo modal de filtro ao invez de adicionar quero que substitua o valor da array
      if(!search) usersArray.value = [...usersArray.value, ...data.usuarios]
      else usersArray.value = data.usuarios

    }


    emitter.on("FilterUser", (valor: any) => {
      if(valor.input) input.value = valor.input;

      pagina.value = 1

      buscaUsuario(pagina.value,true)

    })

    emitter.on("enviaUsuarioAtualizado", (valor: any) => {

      const index = usersArray.value.findIndex(({id}) => id === valor.usuario.id)

      if(index !== -1) usersArray.value[index] = { ...valor.usuario }
      else usersArray.value.unshift({ ...valor.usuario })

    })

    emitter.on("removeUsuarioLista", (valor: any) => {

      const index = usersArray.value.findIndex(({id}) => id === valor.usuario.id)

      if(index !== -1) usersArray.value.splice(index,1)

    })

    function getLocalStorage(){
      lojaFixada.value = JSON.parse(localStorage.getItem('lojaFixada') as any)      
      if(lojaFixada.value){
        idLoja.value = lojaFixada.value && lojaFixada.value.id
      }
    }

    
    
    //----------- colocar o filtro fazendo requisição
    function buscar(){

      if(fastFind.value.length > 2 || !fastFind.value){
          pagina.value = 1
          buscaUsuario(pagina.value, true)
        }
        
     }

  //Ao modificar o parametro da rota faz nova chamada na api
   watch(
    () => route.query.idLoja,
    () => {
      input.value = {
      cargo: "",
      data: ""
    }

      if(route.query.idLoja){
        idLoja.value = route.query.idLoja
        buscaUsuario(pagina.value, true)
      }else{
        idLoja.value = null
        buscaUsuario(pagina.value, true)
      }
    }

   )

   async function pegarLojaId() {
      await emitter.emit("idLojaUser", {
        idLoja: idLoja.value,
      });
    }

    onMounted(async () => {
      modalUsuario.value = new Modal(document.querySelector(`#ModalUsers`));
      idLoja.value = route.query.idLoja || null
      getLocalStorage();
      await buscaUsuario(pagina.value)
      // scroll infinito
      const observer = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) buscaUsuario(pagina.value = pagina.value + 1);
      }).observe(document.querySelector("#load") as any);

    })

    

    return {
      usersArray,
      limite,
      history,
      openModal,
      fechaModal,
      router,
      pagina,
      buscaUsuario,
      usuarioModal,
      idLoja,
      lojaFixada,
      fastFind,
      buscar,
    };
  },
});
